import { useCallback, useEffect, useState } from "react";
import "./style.scss";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const QuarterPicker = ({
  onSelectDate,
  value,
  minDate,
  maxDate,
  annotationSelected,
}: {
  onSelectDate: Function;
  value: Date;
  minDate: Date;
  maxDate: Date;
  annotationSelected?: boolean;
}) => {
  const moment = require("moment");

  let minYear = moment(minDate).isoWeekYear();
  let maxYear = moment(maxDate).isoWeekYear();

  const getVisibility = useCallback(
    (year: number) => {
      let visibility: boolean[] = [];
      for (let q = 1; q <= 4; q++) {
        visibility.push(
          moment(year.toString())
            .quarter(q)
            .isBetween(moment(minDate).subtract(1, "quarter"), maxDate)
        );
      }
      return visibility;
    },
    [minDate, maxDate, moment]
  );

  const [year, setYear] = useState<number>(moment(value).isoWeekYear());
  const [visibility, setVisibility] = useState<boolean[]>(
    getVisibility(moment(value).year())
  );
  const [current, setCurrent] = useState<{ year: number; quarter: number }>({
    year: moment(value).year(),
    quarter: moment(value).quarter(),
  });

  const handleChange = (e: any) => {
    let quarter = e.target.dataset["quarter"] || 1;
    let finalDate = moment(year.toString()).quarter(quarter).toDate();

    onSelectDate(finalDate);
  };

  useEffect(() => {
    setVisibility(getVisibility(year));
    //setShowCompare19(year===2021)
  }, [year, getVisibility]);

  useEffect(() => {
    setCurrent({
      year: moment(value).year(),
      quarter: moment(value).quarter(),
    });
  }, [value, moment]);

  const isSelected = (q: number) => {
    return current.year === year && current.quarter === q && annotationSelected;
  };

  return (
    <div className="ms-DatePicker">
      <div className="ms-DatePicker-picker">
        <div className="ms-DatePicker-holder">
          <div className="ms-DatePicker-frame">
            <div className="ms-DatePicker-wrap-quarter">
              <div className="ms-DatePicker-quarterPicker">
                <div className="ms-DatePicker-header">
                  <div
                    className="ms-DatePicker-currentYear"
                    aria-label="Año"
                    role="button"
                    aria-atomic="true"
                    aria-live="polite"
                    tabIndex={0}
                  >
                    {year}
                  </div>
                  <div className="ms-DatePicker-yearComponents">
                    <div className="ms-DatePicker-navContainer">
                      <button
                        className="ms-DatePicker-prevYear js-prevYear"
                        title="Ir al año anterior"
                        onClick={() => setYear(year - 1)}
                        disabled={year === minYear}
                      >
                        <i data-icon-name="Up" aria-hidden="true">
                          &uarr;
                        </i>
                      </button>
                      <button
                        className="ms-DatePicker-nextYear js-nextYear"
                        title="Ir al año siguiente"
                        onClick={() => setYear(year + 1)}
                        disabled={year === maxYear}
                      >
                        <i data-icon-name="Down" aria-hidden="true">
                          &darr;
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="ms-FocusZone">
                  <div
                    className="ms-DatePicker-optionGrid"
                    role="grid"
                    aria-readonly="true"
                  >
                    <div className="ms-DatePicker-quarterRow" role="row">
                      <button
                        onClick={handleChange}
                        data-quarter={1}
                        disabled={!visibility[0]}
                        role="gridcell"
                        className={`ms-DatePicker-quarterOption${
                          isSelected(1) ? " active" : ""
                        }`}
                        aria-label="Primer trimestre"
                        type="button"
                        tabIndex={0}
                        data-is-focusable="true"
                      >
                        T1
                      </button>
                      <button
                        onClick={handleChange}
                        data-quarter={2}
                        disabled={!visibility[1]}
                        role="gridcell"
                        className={`ms-DatePicker-quarterOption${
                          isSelected(2) ? " active" : ""
                        }`}
                        aria-label="Segundo trimestre"
                        type="button"
                        tabIndex={1}
                        data-is-focusable="true"
                      >
                        T2
                      </button>
                    </div>
                    <div className="ms-DatePicker-quarterRow" role="row">
                      <button
                        onClick={handleChange}
                        data-quarter={3}
                        disabled={!visibility[2]}
                        role="gridcell"
                        className={`ms-DatePicker-quarterOption${
                          isSelected(3) ? " active" : ""
                        }`}
                        aria-label="Tercer trimestre"
                        type="button"
                        tabIndex={2}
                        data-is-focusable="true"
                      >
                        T3
                      </button>
                      <button
                        onClick={handleChange}
                        data-quarter={4}
                        disabled={!visibility[3]}
                        role="gridcell"
                        className={`ms-DatePicker-quarterOption${
                          isSelected(4) ? " active" : ""
                        }`}
                        aria-label="Cuarto trimestre"
                        type="button"
                        tabIndex={3}
                        data-is-focusable="true"
                      >
                        T4
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuarterPicker;
